import genericGroupsConfigAggregator from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-groups/config-aggregator'
import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import genericConfigAggregator from './generic/config-aggregator'
import sliderConfigAggregator from './slider/config-aggregator'
import tabsConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/tabs/config-aggregator'

import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import GenericGroupsShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-groups/generic-groups-shimmer'
import TrendingDestinationsShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/trending-destinations/trending-destinations-shimmer'
import BannerShimmer from './banner/banner-shimmer'
import SliderShimmer from './slider/slider-shimmer'
import GenericRefundShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-refund/generic-refund-shimmer'

const TrendingDestinations = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/trending-destinations/trending-destinations'
		),
	{ loading: TrendingDestinationsShimmer }
)

const BannerSearchDestination = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-search-destination/banner-search-destination'
		),
	{ loading: BannerShimmer }
)

const BannerSearchMood = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-search-mood/banner-search-mood'
		),
	{ loading: BannerShimmer }
)

const BannerSearchPeriod = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-search-period/banner-search-period'
		),
	{ loading: BannerShimmer }
)

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const BannerCtas = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-ctas/banner-ctas'
		),
	{ loading: BannerShimmer }
)

const BannerImage = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image/banner-image'
		),
	{ loading: BannerShimmer }
)

const BannerButton = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-button/banner-button'
		),
	{ loading: BannerShimmer }
)

const BannerIcon = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-icon/banner-icon'
		),
	{ loading: BannerShimmer }
)

const BannerIconText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-icon-text/banner-icon-text'
		),
	{ loading: BannerShimmer }
)

const BannerShare = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-share/banner-share'
		),
	{ loading: BannerShimmer }
)

const SliderMasonryGrid = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-masonry-grid/slider-masonry-grid'
		),
	{ loading: SliderShimmer }
)

const GenericGroups = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-groups/generic-groups'
		),
	{ loading: GenericGroupsShimmer }
)

const AccordionDay = dynamic(() =>
	import(
		'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/accordion-day/accordion-day'
	)
)

const BannerCtasAlternative = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-ctas-alternative/banner-ctas-alternative'
		),
	{ loading: BannerShimmer }
)

const GenericRefund = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-refund/generic-refund'
		),
	{ loading: GenericRefundShimmer }
)

const trendingDestinationsConfig = {
	configAggregator: genericConfigAggregator,
	component: TrendingDestinations,
}

const genericGroupsConfig = {
	configAggregator: genericGroupsConfigAggregator,
	component: GenericGroups,
}

const bannerSearchDestinationConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerSearchDestination,
}

const bannerSearchMoodConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerSearchMood,
}

const bannerSearchPeriodConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerSearchPeriod,
}

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const bannerCtasConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCtas,
}

const bannerImageConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerImage,
}

const bannerButtonConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerButton,
}

const bannerIconConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerIcon,
}

const bannerIconTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerIconText,
}

const bannerShareConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerShare,
}

const sliderMasonryGridConfig = {
	configAggregator: sliderConfigAggregator,
	component: SliderMasonryGrid,
}

const accordionConfig = {
	configAggregator: tabsConfigAggregator,
	component: AccordionDay,
}

const bannerCtasAlternativeConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCtasAlternative,
}

const genericRefundConfig = {
	configAggregator: genericConfigAggregator,
	component: GenericRefund,
}

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'generic-trending-destinations': trendingDestinationsConfig,
	'generic-groups': genericGroupsConfig,
	'banner-search-destination': bannerSearchDestinationConfig,
	'banner-search-mood': bannerSearchMoodConfig,
	'banner-search-period': bannerSearchPeriodConfig,
	'banner-ctas': bannerCtasConfig,
	'banner-image': bannerImageConfig,
	'banner-button': bannerButtonConfig,
	'banner-icon': bannerIconConfig,
	'banner-icon-text': bannerIconTextConfig,
	'banner-share': bannerShareConfig,
	'slider-masonry-grid': sliderMasonryGridConfig,
	'slide-image': bannerImageConfig,
	'slide-button': bannerButtonConfig,
	'slide-icon': bannerIconConfig,
	'slide-icon-text': bannerIconTextConfig,
	'slide-share': bannerShareConfig,
	'slide-ctas': bannerCtasConfig,
	'slide-search-destination': bannerSearchDestinationConfig,
	'slide-search-mood': bannerSearchMoodConfig,
	'slide-search-period': bannerSearchPeriodConfig,
	'tabs-accordion-day': accordionConfig,
	'banner-ctas-alternative': bannerCtasAlternativeConfig,
	'generic-refund': genericRefundConfig,
}

export default customContentTypes
