import genericConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/generic/config-aggregator'

const configAggregator = (node) => {
	const configs = genericConfigAggregator(node)

	let payload
	try {
		const customData = JSON.parse(configs?.customData)
		payload = customData?.payload
	} catch (e) {
		console.error(e)
		payload = {}
	}

	return {
		...configs,
		payload,
	}
}

export default configAggregator
